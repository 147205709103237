<script setup lang="ts">
const { $locales: locales } = useNuxtApp();

const props = defineProps<{
    pronoun: string;
    locale: string;
}>();

const base = computed(() => {
    return locales[props.locale].url;
});

const pronounWithoutDomain = computed(() => {
    return props.pronoun.startsWith(`${base.value}/`)
        ? props.pronoun.substring(base.value.length + 1)
        : props.pronoun;
});

const shortMaxLength = 36;

const { data: pronounName } = useFetch(
    `/api/remote-pronouns-name/${props.locale}/${pronounWithoutDomain.value}`,
    { lazy: true },
);

const formattedPronounName = computed(() => {
    if (!pronounName.value) {
        return pronounWithoutDomain.value.split(',')[0].substring(0, 24);
    }
    if (pronounName.value.length > shortMaxLength) {
        return `${pronounName.value.substr(0, shortMaxLength - 1)}…`;
    }
    return pronounName.value;
});
</script>

<template>
    <span class="badge bg-primary text-white mx-1">
        {{ formattedPronounName }}
    </span>
</template>
