<template>
    <div class="list-group list-group-flare">
        <div class="list-group-item pt-3">
            <h5>
                <Icon v="share" />
                <T>share</T>
            </h5>
        </div>
        <div class="list-group-item small p-2 text-center">
            <input v-model="link" class="form-control form-control-sm mb-2" readonly disabled>
            <Share :url="url" nolabel share-api-separate />
        </div>
        <div class="list-group-item small p-3">
            <h6>
                <T>profile.share.customise</T>
            </h6>
            <div class="form-group my-1">
                <div class="form-check form-switch">
                    <label>
                        <input v-model="local" class="form-check-input" type="checkbox">
                        <T>profile.share.local</T>
                    </label>
                </div>
            </div>
            <div class="form-group my-1">
                <div class="form-check form-switch">
                    <label>
                        <input v-model="atAlternative" class="form-check-input" type="checkbox">
                        <T>profile.share.atAlternative</T>
                    </label>
                </div>
            </div>
            <div v-if="mainPronoun" class="form-group my-1">
                <div class="form-check form-switch">
                    <label>
                        <input v-model="pronouns" class="form-check-input" type="checkbox">
                        <T>profile.share.pronouns</T>
                    </label>
                </div>
            </div>
        </div>
        <a v-if="!showQr" href="#" class="list-group-item list-group-item-action list-group-item-hoverable" @click.prevent="showQr = true">
            <Icon v="scanner" />
            <T>user.qr.header</T>
        </a>
        <div v-else class="list-group-item small p-2 text-center">
            <QrCode
                v-if="showQr"
                :url="url"
                :text1="local ? $t('domain') : 'pronouns.page'"
                :text2="`@${user.username}`"
                download
                border
                style="max-width: 256px; margin: 0 auto"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        user: { required: true },
        showQrStart: { type: Boolean },
        mainPronoun: {},
    },
    data() {
        return {
            showQr: this.showQrStart,
            local: true,
            atAlternative: false,
            pronouns: false,
        };
    },
    computed: {
        link() {
            return (this.local ? this.$t('domain') : 'pronouns.page') +
                (this.atAlternative ? '/u/' : '/@') +
                this.user.username +
                (this.pronouns && this.mainPronoun ? `#${this.mainPronoun.name()}` : '');
        },
        url() {
            return `https://${this.link}`;
        },
    },
};
</script>
