<template>
    <ul class="list-inline">
        <li class="list-inline-item">
            <T>mode.accessibility</T><T>quotation.colon</T>
        </li>
        <li class="list-inline-item">
            <label class="form-check form-switch d-inline-block">
                <input v-model="reducedColours" class="form-check-input" type="checkbox" role="switch">
                <T>mode.reducedColours</T>
            </label>
        </li>
        <li class="list-inline-item">
            <label class="form-check form-switch d-inline-block">
                <input v-model="reducedItems" class="form-check-input" type="checkbox" role="switch">
                <T>mode.reducedItems</T>
            </label>
        </li>
    </ul>
</template>

<script>
import { useMainStore } from '../store/index.ts';

export default {
    setup() {
        return {
            store: useMainStore(),
        };
    },
    data() {
        return {
            reducedColours: false,
            reducedItems: false,
        };
    },
    watch: {
        reducedColours(v) {
            document.body.classList.toggle('reduced-colours', v);
            localStorage.setItem('reducedColours', v);
        },
        reducedItems(v) {
            localStorage.setItem('reducedItems', v);
            this.store.setReducedItems(this.reducedItems);
        },
    },
    mounted() {
        this.reducedColours = localStorage.getItem('reducedColours') === 'true';
        this.reducedItems = localStorage.getItem('reducedItems') === 'true';
        this.store.setReducedItems(this.reducedItems);
    },
};
</script>
